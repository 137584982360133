
.s-speakers {
  .section__head {
    display: flex;
    padding-left: 100px;
    @include md {
      flex-direction: column;
      padding-left: 80px; }
    @include xs {
      padding-left: 60px; } }
  .section__title {
    max-width: 50%;
    width: 100%;
    padding: 80px 0 0;

    @include md {
      max-width: none; }

    @include sm {
      padding-top: 40px; }
    @include xs {
      padding-left: 0; }
    .initial span {
      position: static; } }
  .section__subtitle {
    margin: 96px 0 0;
    max-width: 50%;
    width: 100%;
    padding: 0 0 8px 20px;
    max-width: 480px;
    font-weight: 400;
    font-size: 16px;
    @include md {
      margin: 10px 0 0;
      max-width: none;
      padding-left: 0; }
    @include sm {
      font-size: 14px;
      margin-left: 0;
      width: auto; } }

  @include temp {
    .container {
      padding: 60px 20px; } } }

.speakers-list, .mc-list {
  margin: 144px -1.6% 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(254px, 1fr));
  gap: 40px 0;
  @include sm {
    gap: 40px 20px;
    margin-top: 60px; }

  &__item {
    position: relative;
    &:nth-child(2n) {
      margin-top: -38px;
      @include md {
        margin-top: 0; } }
    &:nth-child(3n + 2) {
      @include md {
        margin-top: -38px; }
      @include sm {
        margin-top: 0; } }

    &.fill-width {
      width: 100%; } }

  &__item {
    &_cfp {

      padding: 30px 20px;
      @include md {
        padding: 25px 20px; }
      @include sm {
        padding: 0; }
      .btn {
        @extend .btn--green;
        margin-top: 20px; } }
    &.with-button {
      &:hover {
        .team__img-wrap {
          &:after {
            display: none; }

          img {
            transform: translate(0, 0); } } } } } }

.committee, .mc {
  .section__head {

    display: flex;
    justify-content: center; }
  .section__title {
    width: max-content;
    @include xs {
      padding-left: 0; }
    .initial span {
      position: static; } }
  .speakers-list {
    &__item {
      margin-top: 0 !important; } } }

.speaker {
  padding: 20px;
  position: relative;
  display: grid;
  grid-auto-columns: 100%;
  @include sm {
    padding: 0; }
  &__info {
    p {
      word-wrap: break-word;
      overflow: visible;
      text-overflow: unset;
      -webkit-line-clamp: initial;

      &:not(:first-child) {
        display: initial; } } }

  &__talk {
    margin-top: 12px;

    span {
      font-weight: 500; }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }

  &__item-tag {
    display: none; }

  &__photo {
    position: relative;

    background: $themeColor;
    grid-row: 1/2;
    grid-column: 1/2;

    picture {
      position: relative;
      z-index: 2;
      display: block;
      background: $themeColor;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        background: $themeColor;
        opacity: 0; } }
    img {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: cover; }

    .basic-btn {
      width: 100%; } }

  &__content {
    margin: 0 0 10px; }

  &__name {
    font-size: 26px;
    font-weight: 700;
    margin: 20px 0 4px;
    padding: 0;
    line-height: 1;
    transition: all ease 0.1s;

    @include sm {
      font-size: 24px; } }

  &__company {
    font-size: 14px;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 4px 8px;
    align-items: center; }

  &__talk {
    font-size: 14px; }

  &__desc {
    font-size: 14px;
    margin: 10px 0 0;
    line-height: 1.52;
    &:not(:has(p)) {
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical; }
    p {
      margin: 0;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      &:not(:first-child) {
        display: none; } }
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }
  &__tag {
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 23px;
    padding: 0 8px 0 8px;
    font-weight: 700;
    margin: 0;
    z-index: 2;
    pointer-events: auto;

    @include xs {
      right: 0;
      top: 0; }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: var(--tagBG, #000);
      z-index: -1; } }
  &__socials {
    position: relative;

    z-index: 4;
    display: flex;
    gap: 4px;
    padding: 10px;
    grid-row: 1/2;
    grid-column: 1/2;
    margin-top: auto;
    margin-right: auto; }
  &__social {

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: rgba(0,0,0,0.7);
      border-radius: 50%;
      @include xs {
        width: 30px;
        height: 30px; }
      .icon {
        color: $themeColor;
        fill: currentColor; }
      &:hover, &:focus-visible {
        background: rgba(0, 0, 0, 0.75);
        .icon {

          fill: #fff; } }
      &:focus-visible {
        outline: 2px solid rgba(#fff, 0.9) !important; } } }

  .activity-holder {
    position: absolute;
    transition: top 150ms ease;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 20px;

    opacity: 0;

    transition: all ease 0.2s;
    cursor: pointer;

    &:hover, &:focus-visible {
      opacity: 1;
      outline: none !important;
      .slider {
        transform: translate(15px, -15px); }
      & ~ picture {
        display: block;
        transform: translate(15px, -15px);

        img {
          filter: grayscale(100%); }
        &:after {
          opacity: 0.4; } }

      & ~ .speaker__socials {
        transform: translate(15px, -15px); }

      & ~ .team__content .team__name {
        color: $themeColor; } }

    @include xs {
      padding: 15px; }

    a {
      color: $white;
      opacity: 0.7;
      margin: 18px 0 0;
      display: inline-block;
      text-decoration: none;

      &:hover {
        opacity: 1; } }

    .slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #fff;
      font-weight: 700;
      white-space: nowrap;
      text-transform: uppercase; } }

  .advices-techs {
    margin: 0;

    &__item {
      width: 25px;
      margin: 0 5px 0 0;
      flex: 0; }

    &__img-wrap {
      width: 28px;
      height: 28px;
      margin: 0;

      img {
        width: 100%;
        height: auto; } } } }
