.s-teams {
  position: relative;

  &__decor-diver {
    position: absolute;
    top: 113px;
    left: 50%;
    margin: 0 0 0 270px;
    width: 356px;
    height: 872px;
    background: url(../img/decor_diver-2.png) center center;
    background-size: cover;
    z-index: 0;

    @include temp {
      top: 50px;
      width: 284px;
      height: 694px;
      left: auto;
      right: 30px;
      margin: 0;
      opacity: 0.6; }

    @include xs {
      top: 100px;
      width: 180px;
      height: 442px;
      left: auto;
      right: 30px;
      margin: 0; } }

  .container {
    padding: 106px 0 56px;

    @include temp {
      padding: 56px 0 56px; }

    @include sm {
      padding: 28px 20px; } }

  .section {

    &__title {
 }      // font-weight: 600

    &__head-l {
      max-width: 850px; } }

  .btn {
    margin-top: 40px; } }
