.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all .3s ease;
  z-index: 10;
  .container {
    padding: 0 !important; }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    flex: 1;
    @include md {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      justify-content: flex-start;
      flex-direction: column;
      padding: 20px;
      gap: 30px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      z-index: 3;
      background: #fff; }
    &.is-open {
      display: flex; } }

  &.live {
    top: auto;
    bottom: 0;
    opacity: 0; }

  &.is-sticky,
  &.visible {
    background: $themeColor;
    opacity: 1;

    .logo-mini {
      opacity: 1;
      transform: rotate(0) scale(1); }

    .socials {
      &__link {
        fill: $themeColor; } } }

  &.white:not(.is-sticky) {
    @include rmin(1025) {
      .nav__link {
        color: #fff; }
      .socials__link {
        .icon {
          fill: #fff; } } } }

  &__logo {
    margin: 0 30px 0 0;
    @include md {
      display: none; } }

  &__nav {
    flex: 1 0 auto;
    @include md {
      flex: none;
      margin: auto 0;
      padding: 20px 0; } }

  &__socials {
    margin: 0 0 0 14px;
    display: flex;
    flex-wrap: wrap;
    flex: none;
    @include md {
      justify-content: center;
      margin: 0 0 10px 0; } }

  &__btn {
    margin: -7px 0 -7px 10px;
    flex: none;
    @include md {
      margin: 10px auto 15px; }

    &.menu-btn {
      display: none;

      @include md {
        display: inline-flex; } } } }

.logo-mini {
  position: relative;
  margin: 0;
  height: 44px;
  width: 56px;
  opacity: 0;
  transform: rotate(120deg) scale(0.5);
  transition: all 0.5s;

  &__link {
    display: block;
    height: 100%;
    background: url(../img/logo_mini.svg) no-repeat;
    transition: none;
    background-size: 100%; } }

.nav {
  font-size: 14px;
  display: flex;
  padding: 0 10px;
  margin: 0 auto;
  list-style: none;
  font-weight: 700;
  flex: 1 1 auto;
  @include md {
    display: block;
    text-align: center; }

  &__item {
    position: relative;
    margin-right: 5px;

    &:before {
      content: '';
      position: absolute;
      left: -4px;
      top: 50%;
      transform: translateY(-1px);
      height: 2px;
      background: $black;
      width: 12px;
      opacity: 0;
      transition: all .3s ease;
      transform: translateX(-10px);
      @include md {
        display: none; } }

    &.active,
    &:hover {
      &:before {
        opacity: 1;
        transform: translateX(0); } } }

  &__link {
    display: flex;
    height: 40px;
    text-decoration: none;
    align-items: center;
    padding: 0 10px;
    color: $black;
    @include md {
      display: block;
      height: auto;
      padding: 10px;
      font-size: 16px; } } }

.socials {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;

  &__item {
    margin: 0 0 0 0; }

  &__link {
    position: relative;
    display: flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    .icon {
      fill: $black; }
    &:hover {
      .icon {
        opacity: 0.7; }

      &:before {
        opacity: 1; } } }

  &__img {
    width: 17px;
    height: 14px;
    width: 100%;
    display: block;
    fill: $black;
    transition: all 0.2s ease; } }

.burger {
  position: fixed;
  top: 10px;
  right: 16px;
  width: 40px;
  height: 40px;
  display: none;
  cursor: pointer;
  background: none;
  justify-content: center;
  padding: 0;
  border: none;
  outline: none;
  z-index: 11;

  span {
    display: block;
    width: 24px;
    height: 2px;
    background: $themeColor;
    margin: 10px auto;
    transition: all ease 0.2s; }

  &.is-open {
    span {
      background: $black;
      &:nth-child(1) {
        transform: translateY(6px) rotate(45deg); }

      &:nth-child(2) {
        transform: translateY(-6px) rotate(-45deg); } } }

  @include md {
    display: block; } }
