.s-cta {
  background: $themeColor;

  .container {
    padding: 70px 20px;

    @include sm {
      padding: 45px 20px; } } }

.cta {

  &__desc {
    color: $black;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    line-height: 1.12;
    max-width: 780px;
    margin: 0 auto;

    @include sm {
      font-size: 28px; }

    @include xs {
      font-size: 24px; } }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 45px 0 0; } }
