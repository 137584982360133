.video-rooms {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0 0;
  gap: 45px 45px;
  color: $white;
  @include md {
    gap: 25px 25px;
    margin: 30px 0 0; }

  @include sm {
    gap: 18px 18px; }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $black;
    flex: 1 1 calc(50% - 45px);
    transition: all ease 0.1s;
    max-width: calc(50% - 22px);

    &:hover {
      cursor: pointer;
      background: #082E1A;

      .video-rooms {
        &__btn {
          background: $themeColor;
          color: $black;

          &:after,
          &:before {
            content: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.4px" height="11.4px" viewBox="0 0 25.4 11.4" style="enable-background:new 0 0 25.4 11.4;" xml:space="preserve"><polygon fill="%23000000" points="19.7,0 18.3,1.4 21.6,4.7 0,4.7 0,6.7 21.6,6.7 18.3,10 19.7,11.4 25.4,5.7"/></svg>'); } } } }

    @include md {
      max-width: calc(50% - 12px); }

    @include sm {
      padding-bottom: 70px; }

    @include xs {
      flex: 1 1 100%;
      max-width: none; } }

  &__head {
    padding: 30px 30px 20px;
    display: flex;
    align-items: center;
    @include md {
      padding: 20px 20px 20px; }
    @include sm {
      flex-direction: column;
      text-align: center; } }

  &__img-wrap {
    margin: 0 35px 0 0;

    @include sm {
      margin: 0 0 20px; } }

  &__img {
    width: 90px;
    height: 90px; }

  &__title {
    font-size: 24px;
    line-height: 1.2;
    @include md {
      font-size: 20px; } }

  &__bottom {
    display: flex;

    flex: 1 1 auto;

    @include sm {
      flex: 0;
      flex-direction: column-reverse; } }

  &__btn {
    min-width: 140px;
    width: 140px;
    min-height: 46px;
    border-color: $themeColor;
    height: auto;
    @include sm {
      width: 100%;
      height: 56px;
      margin: auto 0 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; } }

  &__users {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;

    @include sm {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      min-width: 0; } }

  &__avatar {
    border-radius: 50%;
    object-fit: cover; }

  &__user {
    flex: 1 1 50%;
    display: flex;
    padding: 10px 15px;
    align-items: center;

    @include sm {
      flex: 1 1 auto; }

    img {
      width: 40px;
      height: 40px;
      margin: 0 10px 0 0; } }

  &__name {
    margin: 0;
    line-height: 1;
    font-size: 14px; } }
