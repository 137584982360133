.mcs {
  position: relative;

  .container {
    padding: 20px 20px 0px;
    @include sm {
      padding-bottom: 0; } }

  .team-mc {
    margin-top: -50px; }

  &__decor-fish {
    position: absolute;
    top: 200px;
    left: 50%;
    margin: 0 0 0 -503px;
    width: 322px;
    height: 200px;
    background: url(../img/decor_fish.png) center center no-repeat;
    background-size: 100%; }

  &__decor-sub {
    position: absolute;
    bottom: 80px;
    left: 50%;
    margin: 0 0 0 118px;
    width: 543px;
    height: 350px;
    background: url(../img/decor_submarine.png) center center no-repeat;
    background-size: 100%; }

  &__decor-mic {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0 0 0 540px;
    width: 202px;
    height: 414px;
    background: url(../img/decor_mic.png) center center no-repeat;
    background-size: 100%;

    @include xl {
      left: auto;
      right: 0; }

    @include sm {
      display: none; } }

  // &:before
  //   position: absolute
  //   content: ''
  //   left: 0
  //   top: 0
  //   right: 0
  //   bottom: 0
  //   background: url(../img/bg-dots.png) center top
  //   z-index: -1

  // &:after
  //   position: absolute
  //   content: ''
  //   left: 0
  //   top: 0
  //   right: 0
  //   height: 600px
  //   background: url(../img/bg-dots.png) center top
  //   z-index: -1
  //   background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)

  @include md {
    .team-mc {
      margin-top: 40px; }

    .container {
      padding: 0 20px 50px; } }

  @include xs {
    &__decor-fish {
      top: 54px;
      margin: 0px 0 0 -18px;
      width: 170px;
      height: 108px; }

    &__decor-sub {
      top: 570px;
      bottom: auto;
      margin: 0 0 0 -28px;
      width: 240px;
      height: 154px; } } }

.team-mc {
  &__item {
    position: relative;
    margin: 0 0 0 auto;
    text-align: right;
    display: flex;
    flex-direction: row-reverse;

    &:nth-child(2n) {
      margin: 0 auto 20px 0;
      text-align: left;
      flex-direction: row;

      .team-mc__content {
        right: auto;
        margin: 0 0 0 -25px; }

      .team-mc__desc {} } }

  &__img-wrap {
    width: 370px;
    height: 370px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__content {
    position: relative;
    padding-top: 110px;
    margin: 0 -25px 0 0;
    width: 370px;
    z-index: 1; }

  &__head {
    padding: 0 48px; }

  &__desc {
    background: $themeColor;
    padding: 48px 48px 14px;
    position: relative;
    text-align: left; }

  &__bottom {
    background: $themeColor;
    padding: 0 46px 40px;
    display: flex;
    justify-content: space-between; }

  &__name {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2;
    padding: 0;
    margin: 0 0 -3px; }

  &__company {
    font-size: 20px;
    line-height: 1.38;
    padding: 0;
    margin: 0px 0 24px; }

  &__info {
    font-size: 14px;
    line-height: 1.5;
    padding: 0;
    margin: 0; }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0; }

  &__tag {
    margin: 0;
    font-size: 14px;
    font-weight: 600; }

  .socials {
    margin: 0;

    &__item {
      margin: 0; }

    &__link {
      &:hover {
        .socials__img {
          transform: scale(1.4);
          fill: $white; } } }

    &__img {
      transform: scale(1.4);
      fill: $black; } }

  @include md {
    &__item {
      height: auto;
      margin-bottom: 40px; }

    &__content {
      padding-top: 30px;
      flex: 1 1 auto; }

    &__img-wrap {
      width: 250px;
      height: 250px;
      flex: 0 0 auto; } }

  @include sm {
    &__item {
      width: 100%;
      flex-direction: column;

      &:nth-child(2n) {
        margin: 0 auto 20px 0;
        text-align: left;
        flex-direction: column;

        .team-mc__content {
          margin: -20px 0 0 60px; }

        .team-mc__img-wrap {
          margin: 0 0 0 0; } } }

    &__img-wrap {
      margin: 0 0 0 auto;
      flex: 0 0; }

    &__content {
      position: relative;
      margin: -20px 60px 0 0;
      right: 0;
      padding-top: 20px;
      width: auto;
      background: $themeColor; }

    &__head {
      z-index: 1;
      padding: 0 20px;
      position: relative; }

    &__company {
      margin-bottom: -20px; }

    &__desc {
      padding: 24px 24px 0; }

    &__bottom {
      padding: 0 24px 14px; } }

  @include xs {
    margin-left: -20px;
    margin-right: -20px;

    &__item {
      width: 100%;
      margin-bottom: 136px;
      padding-top: 1px;

      &:nth-child(2n) {
        margin: 0 auto 20px 0;
        text-align: left;

        .team-mc__content {
          margin: -20px auto 0 30px; }

        .team-mc__img-wrap {
          margin: 0 auto 0 0; } } }

    &__content {
      margin: -20px 30px 0 auto; }

    &__desc {
      padding-top: 12px;

      &:after {
        top: -48px; } }

    &__head {
      padding: 24px 24px 0;
      background: $themeColor;
      text-align: left; }

    &__name {
      font-size: 20px;
      margin-bottom: 2px; }

    &__company {
      font-size: 14px;
      margin: 0; }

    &__info {
      font-size: 12px; } } }
