.s-for-boss {
  position: relative;

  .container {
    padding: 130px 20px 200px;
    position: relative;

    @include md {
      padding: 80px 20px 60px; } }

  .section {

    &__head-r {
      .btn {
        margin-top: 40px; } } }

  &__decor-diver {
    position: absolute;
    top: 178px;
    left: 50%;
    margin: 0 0 0 -400px;
    width: 338px;
    height: 578px;
    background: url(../img/decor_diver.png) center center;
    background-size: cover;
    z-index: 0;

    @include temp {
      top: 250px;
      width: 284px;
      height: 478px;
      left: auto;
      right: 30px;
      margin: 0; }

    @include xs {
      top: 300px;
      width: 180px;
      height: 310px;
      left: auto;
      right: 30px;
      margin: 0; } } }
