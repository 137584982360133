.s-perks-info {
  position: relative;

  .section__head-l {
    max-width: none; }

  .section__title {
    color: $themeColor; }

  &__decor {
    position: absolute;
    top: 30px;
    left: 0;
    width: 133px;
    height: 522px;
    background: url(../img/decor_perks.png) center center;
    background-size: cover;
    z-index: 0;

    @include temp {
      left: -50px; }

    @include md {
      top: 0;
      width: 100px;
      height: 392px;
      opacity: 0.6; } } }

.perks-info {
  display: flex;
  margin: 64px 0 0;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;

    @include temp {
      height: 8px; } }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px; }

  &::-webkit-scrollbar-thumb {
    background: #0a0a0a;
    border: 0px solid #fff;
    border-radius: 0px;
    cursor: pointer; }

  &::-webkit-scrollbar-thumb:hover {
    background: #000; }

  &::-webkit-scrollbar-thumb:active {
    background: #000; }

  &::-webkit-scrollbar-track {
    background: #333;
    border: 0px solid #fff;
    border-radius: 0px; }

  &::-webkit-scrollbar-track:hover {
    background: #333; }

  &::-webkit-scrollbar-track:active {
    background: #333; }

  &::-webkit-scrollbar-corner {
    background: transparent; }

  @include md {
    margin: 64px -20px 0;

    &:after,
    &:before {
      content: '';
      min-width: 20px;
      display: block;
      min-height: 100%; } }

  &__item {
    display: block;
    text-align: center;
    color: $white;
    flex: 1 1;
    min-width: 228px;

    &:not(:first-child) {
      margin: 0 0 0 20px; } }

  &__img {
    width: 70px;
    height: 70px;
    padding: 0;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.2;
    margin: 20px 0 16px; }

  &__desc {
    font-size: 16px;
    line-height: 1.52;
    margin: 16px 0 16px; } }
