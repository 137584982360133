.intro {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  background: url(../img/intro_bg.png) center center no-repeat;
  background-size: cover;

  &.height-auto {
    min-height: 0;
    padding-top: 40px;
    .intro__logo {
      @include md {
        max-width: 150px; } }
    .container {
      min-height: 0;
      @include md {
        padding-bottom: 40px !important; } } }

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    padding: 80px 20px;

    @include md {
      padding: 20px 10px 20px; }

    @include xs {
      padding: 18px 10px 116px; } }

  &__logo {

    max-width: 458px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: block;
    @include md {
      max-width: 368px; }
    @include sm {
      max-width: 238px; }
    @include xs {
      max-width: 188px;
      margin-bottom: 20px; }
    img {
      display: block;
      max-width: 100%; } }

  &__octo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 0 0 -1110px;
    width: 813px;
    height: 594px;
    background: url(../img/octo.png) center center no-repeat;
    background-size: 100%;

    &-text {
      position: absolute;
      right: 118px;
      top: 89px;
      line-height: 1.54;
      font-size: 14px;
      text-align: center;
      font-weight: 600;
      height: 63px;
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center; } }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto 0;
    width: 100%; }

  &__desc {
    max-width: 460px;
    text-align: center;
    margin: 22px auto;
    line-height: 1.72;
    font-size: 14px;
    font-weight: 400; }

  .hero__btn {
    position: absolute;
    margin: 0;
    z-index: 1;
    right: 0;
    bottom: -8px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include md {
      bottom: 0; }

    @include xs {
      left: 0;
      right: 0;
      gap: 0; }

    .btn {
      width: 100%;
      &--border {
        @include xs {
          color: #000 !important;
          background: #fff;
          &:after,
          &:before {
            content: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.4px" height="11.4px" viewBox="0 0 25.4 11.4" style="enable-background:new 0 0 25.4 11.4;" xml:space="preserve"><polygon fill="%23000000" points="19.7,0 18.3,1.4 21.6,4.7 0,4.7 0,6.7 21.6,6.7 18.3,10 19.7,11.4 25.4,5.7"/></svg>') !important; } } } } }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    z-index: -1;
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
      pointer-events: none; }

    @media (prefers-reduced-motion) {
      display: none; } }

  @include md {
    &__reserve {
      margin: auto 0 0 0;
      bottom: 4px; }

    &__octo {
      margin: 0 0 0 -950px; } }

  @include sm {
    &__desc {
      font-size: 13px;
      line-height: 1.4;
      margin: 9px auto; }

    &__octo {
      margin: 290px 0 0 -620px; } }

  @include xs {
    &__octo {
      top: 0;
      transform: translateY(0);
      width: 546px;
      height: 426px;
      margin: 200px 0 0 -422px;

      &-text {
        font-size: 10px;
        right: 57px;
        top: 62px; } }

    &__content {
      position: initial;
      margin: 0; }

    &__reserve {
      margin: 0;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0; } }

  @media only screen and (min-device-height: 667px) and (max-device-width: 480px) {
    min-height: 0;

    .container {
      min-height: 667px; } } }

.hero__partner {
  position: absolute;
  left: 0;
  bottom: 37px;
  z-index: 1;
  @include md {
    bottom: 4px;
    left: 10px; }
  @include xs {
    bottom: 140px;
    left: 22px; }
  &-img img {
    height: 50px;
    @include sm {
      height: 40px; } } }

.logo {
  height: 266px;
  width: 458px;
  margin: 0 auto 30px;
  position: relative;
  z-index: 1;

  &__link {
    display: block;
    height: 100%;
    background: url(../img/logo.svg) no-repeat;
    transition: none;
    background-size: 100%; }

  @include md {
    height: 214px;
    width: 368px; }

  @include sm {
    height: 140px;
    width: 238px;
    margin: 38px auto 18px; }

  @include xs {
    margin: 12px auto 18px; } }

.remote-sign {
  position: relative;
  font-weight: 600;
  color: $black;
  margin: -22px auto 18px;
  font-size: 18px;
  padding: 0 8px;
  display: none;

  span {
    font-weight: 500;
    font-size: 42px;
    font-family: $rb; }

  @include sm {
    margin: 6px auto 18px;
    font-size: 14px;
    padding: 0 4px; }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 11px;
    background: darken($themeColor, 10%);
    height: 11px;
    z-index: -1; } }

.logo-desc {
  font-family: $rb;
  font-size: 80px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin: 20px auto 0;

  span {
    font-size: 65px;
    display: block; }

  @include md {
    font-size: 72px;

    span {
      font-size: 55px; } }

  @include sm {
    font-size: 50px;

    span {
      font-size: 38px; } }
  @include xs {
    font-size: 36px;

    span {
      font-size: 28px; } } }

.stats {
  padding: 0;
  margin: 42px 0 0;
  list-style: none;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  @include md {
    margin: 42px 0 0; }

  @include sm {
    margin: 12px 0 0; }

  &__item {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    min-width: 160px;

    @include xl {
      min-width: 0;
      width: 140px; }

    @include md {
      min-width: 0;
      width: 110px; }

    @include sm {
      width: 33%; } }

  &__num {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.25;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;

    @include md {
      font-size: 16px; }

    span {
      font-weight: 500;
      font-size: 45px;
      display: inline-block;
      position: relative;
      margin: 0 auto 4px;
      padding: 0 10px;

      @include md {
        font-size: 36px; }

      @include sm {
        margin: 0 auto 0;
        font-size: 24px; }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        top: 53%;
        background: darken($themeColor, 10%);
        z-index: -1; } } }

  &__desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.36;
    margin: 8px auto 0;
    padding: 0;
    text-align: center;
    min-width: 0;
    max-width: 160px;

    @include md {
      font-size: 14px; }

    @include sm {
      font-size: 10px;
      margin: 0 auto; } } }

.big-day {
  position: absolute;
  right: 0;
  top: 182px;
  font-weight: 500;
  text-align: right;
  z-index: 1;

  &__mon {
    font-size: 40px;
    white-space: nowrap;
    p {
      margin: 0; }

    strong {
      margin: 0 -3px 0 0;
      position: relative;
      top: -6px;
      font-family: $rb;
      font-size: 140px;
      font-weight: 400;
      line-height: 100px;
      color: #24fc89;
      display: inline-block;
      @include sm {
        line-height: 72px;

        font-size: 110px; } } }

  &__date {
    position: relative;
    font-size: 28px;
    line-height: 1.2;
    margin: 6px 0 0;

    span {
      display: inline-block;
      position: relative;
      margin: 0 auto;
      padding: 0 0 0 0px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        top: 53%;
        background: darken($themeColor, 10%);
        z-index: -1; } } }

  @include md {
    top: 0;
    right: 0;
    position: relative;
    display: flex;
    width: 100%;
    max-width: 200px;
    padding-top: 140px;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    justify-content: space-between;

    &__mon {
      line-height: 1; }

    &__date {
      line-height: 1; } }

  @include sm {
    max-width: 160px;
    position: relative;
    padding-top: 200px;

    left: -40px;

    &__wrap {
      position: relative;
      top: -4px;
      margin: 0 0 0 auto; }

    &__mon {
      font-size: 28px; }

    &__date {
      font-size: 18px; } }

  @include xs {
    padding-top: Min(100%, 126px);
    margin: auto 10px 24px;

    max-width: none;
    width: auto;
    left: 0;

    &__mon {
      font-size: 28px; } } }

.clock {
  position: absolute;
  top: 80px;
  padding-right: 80px;
  right: -80px;
  width: 270px;
  height: 248px;
  background: url(../img/decor_clock.png) center center no-repeat;
  background-size: cover; }

// .timer
//   font-size: 36px
//   font-family: $rb
//   width: 250px
//   display: flex
//   align-items: center
//   justify-content: flex-end
//   position: absolute
//   right: 80px
//   top: 113px
//   height: 76px
//   background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)

@media (min-width: 68px) {
  .intro.white {
    color: #fff;
    background: #4B4B4B url(../img/poster.webp) no-repeat center;
    background-size: cover;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 30%;
      background: linear-gradient(to bottom, rgba(0,0,0, 0.45), rgba(0,0,0, 0)); }
    .intro__octo {
      display: none; }
    .intro__important-guys {
      background: url(../img/fr-logo-text-white.png) center center no-repeat;
      background-size: 100%; }
    .hero__btn {
      .btn:not(.btn--border) {
        background: var(--accentColor);
        border-color: var(--accentColor);
        color: #000;
        &:after,
        &:before {
          content: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.4px" height="11.4px" viewBox="0 0 25.4 11.4" style="enable-background:new 0 0 25.4 11.4;" xml:space="preserve"><polygon fill="%23000000" points="19.7,0 18.3,1.4 21.6,4.7 0,4.7 0,6.7 21.6,6.7 18.3,10 19.7,11.4 25.4,5.7"/></svg>'); } }
      .btn--border {
        border-color: #fff;
        color: #fff;
        &:after,
        &:before {
          content: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.4px" height="11.4px" viewBox="0 0 25.4 11.4" style="enable-background:new 0 0 25.4 11.4;" xml:space="preserve"><polygon fill="%23ffffff" points="19.7,0 18.3,1.4 21.6,4.7 0,4.7 0,6.7 21.6,6.7 18.3,10 19.7,11.4 25.4,5.7"/></svg>'); } } } }

  .stats__num span:after, .big-day__date span:after {
    background: darken($themeColor, 22%); } }
