.articles {
	color: $black;
	@include md {
		padding-top: 40px; }
	&__title {
		font-size: 33px;
		line-height: 1.39;
		font-weight: 600;
		text-align: center;
		max-width: 627px;
		margin: 0 auto 85px;
		@include md {
			margin-bottom: 20px;
			font-size: 28px; }
		@include sm {
			font-size: 22px; } }

	&__subtitle {
		text-align: center;
		font-size: 22px;
		max-width: 627px;
		margin: 0 auto;
		@include md {
			font-size: 20px; }
		@include sm {
			font-size: 18px; } }

	&__content {
		padding: 0 40px;
		@include md {
			padding: 0 20px; } }

	.container {
		padding-top: 80px;
		padding-bottom: 80px; } }

.article {
	font-size: 14px;
	max-width: 960px;
	margin: 20px auto;

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 35px;
		margin: 82px 0 0; }

	p {
		margin: 8px 0 22px;
		line-height: 1.5; }

	@include sm {
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: 28px;
			margin: 60px 0 0; } }


	@include xs {
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: 22px;
			margin: 40px 0 0; } }

	padding: 100px 0;

	@include md {
		padding: 30px 0; }
	&:not(:last-child) {
		border-bottom: 1px solid #202421; }
	&__inner {
		display: flex;
		justify-content: space-between;
		max-width: 1274px;
		margin: 0 auto;
		@include md {
			flex-direction: column; } }
	&__img {
		width: 47%;
		margin-right: 60px;
		flex-shrink: 0;
		padding-top: 5px;
		@include md {
			margin-right: 0;
			width: 100%;
			margin-bottom: 20px;
			padding-top: 0; }
		img {
			width: 100%;
			@include md {
				width: auto;
				max-width: 100%;
				display: block;
				margin: 0 auto; } } }
	&__content,
	&__title {
		font-size: 26px;
		color: $black;
		flex: 1 1 auto;
		@include bold;
		margin-bottom: 35px;
		@include md {
			margin-bottom: 15px; } }
	&__description {
		color: #888;
		font-size: 15px;
		line-height: 2;
		margin-bottom: 43px;
		letter-spacing: 1.1px;
		@include md {
			margin-bottom: 20px; }
		@include sm {
			color: #aaaaaa; }

		ul {
			list-style: disc; }

		a {
			color: #fff; }

		p {
			&:not(:last-child) {
				margin-bottom: 23px;
				@include md {
					margin-bottom: 10px; } } } }
	&__link {
		text-decoration: none;
		color: #00ff00;
		font-size: 14px;
		text-transform: uppercase;
		&:hover {
			text-decoration: underline; } } }

.styled-text {
	margin: 32px 0 0;
	line-height: 1.5;

	p {
		margin: 18px 0 0;

		@include sm {
			font-size: 14px; } }

	h3 {
		position: relative;
		color: $themeColor;
		padding: 0 0 0 105px;
		font-size: 18px;
		margin: 36px 0 18px;

		@include sm {
			font-size: 16px; }

		@include xs {
			padding-left: 55px; }

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 76px;
			height: 32px;
			background: url(../img/pointer.png) center center;
			background-size: cover;

			@include xs {
				width: 38px;
				height: 16px;
				top: 5px; } }

		& + ul {
			padding-left: 105px;

			@include xs {
				padding-left: 55px; } } }

	ul {
		color: $themeColor;
		font-size: 18px;
		font-weight: 300;
		margin: 18px 0 36px;
		line-height: 1.38;
		list-style: none;

		@include sm {
			font-size: 16px; }

		li {
			padding-left: 22px;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 9px;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: $themeColor; } } } }
