
.s-features {
    position: relative;

    &__decor {
        position: absolute;
        top: 636px;
        left: 50%;
        margin: 0 0 0 548px;
        width: 200px;
        height: 200px;
        background: url(../img/decor_disco_2.png) center center;
        background-size: cover;
        z-index: 0;

        @include temp {
            left: auto;
            right: -30px;
            margin: 0; } }

    &__decor-2 {
        position: absolute;
        bottom: 87px;
        left: 50%;
        width: 240px;
        height: 220px;
        margin: 0 0 0 -158px;
        background: url(../img/decor_gamepad.png) center center;
        background-size: cover;
        z-index: 0;

        @include xs {
            left: auto;
            right: 0;
            bottom: 350px; } } }

.features {

    .feature-item {
        max-width: 50%;

        @include temp {
            max-width: 100%; } } }

.feature-item {
    max-width: 590px;
    display: flex;

    @include temp {
        &:not(:first-child) {
            margin: 15px 0 0; } }

    @include xs {
        flex-direction: column;
        &:not(:first-child) {
            margin: 30px 0 0; } }

    &:nth-child(odd) {
        margin: 0 0 0 52%;

        @include temp {
            margin: 15px 0 0 0; } }

    &__images {
        flex: 0 0 auto;
        width: 280px;
        max-width: 50%;
        height: 0;
        margin: 0 20px 0 0;

        @include temp {
            height: auto;
            width: 220px; }

        @include xs {
            margin: 0 0 2px 0;
            max-width: none;
            width: 205px; }

        img {
            width: 100%;
            height: auto;
            display: block;

            &:not(:first-child) {
                margin: 15px 0 0; } } }

    &__content {
        flex: 1 1;
        padding: 0;
        max-width: 50%;

        &.full-width {
            max-width: 100%; }

        @include temp {
            height: auto;
            max-width: 100%; } }

    &__title {
        position: relative;
        font-size: 35px;
        line-height: 1;
        font-weight: 500;
        margin: 0;
        padding: 25px 0 0 30px;

        @include sm {
            font-size: 26px;
            line-height: 1.4;

            .initial {
                top: -3px; } }

        .initial {
            position: relative;
            top: -4px;
            width: 0;
            font-family: $rb;
            font-size: 70px;
            line-height: 24px;
            font-weight: 400;
            margin: 0 0 0 0;
            color: $themeColor;

            span {
                display: inline-block;
                position: absolute;
                right: 100%; } } }

    &__descr {
        margin: 22px 0 0;
        font-size: 16px;
        line-height: 1.5;
        max-width: 280px;

        @include temp {
            max-width: none; }

        @include sm {
            margin: 10px 0 0;
            font-size: 14px;
            line-height: 1.8; } } }

.networkers {
    display: grid;
    grid-template-columns: repeat(5 , minmax(50px, 1fr));
    gap: 15px 15px;
    max-width: 570px;

    @include temp {
        margin: 50px 0 0;
        gap: 8px; }

    &__item {
        position: relative;

        &:nth-child(5n - 4),
        &:nth-child(5n - 2),
        &:nth-child(5n - 0) {
            margin: -20px 0 0; } }

    &__item-wrap {
        position: relative;
        display: flex;
        width: 100%;
        padding: 122% 0 0; }

    &__speaker {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; }

    &__time {
        position: absolute;
        bottom: 8px;
        right: 8px; }

    &__face-wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F7F7F7; }

    &__face {
        position: relative;
        display: block;
        height: 48%;
        width: 100%;
        fill: #D3D3D3; } }
