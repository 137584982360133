.s-tweets {
	padding: clamp(30px, 5vw, 50px) 0 1px;
	background: $black;

	.container {
		padding: 0; } }

.tweets {
	columns: 3;
	column-gap: 20px;

	@include md {
		columns: 2; }
	@include xs {
		columns: auto;
		display: flex;
		flex-direction: column;
		align-items: center; }

	&__item {
		display: inline-block;
		width: 100%;

		@include xs {
			max-width: 400px;
			margin-bottom: 0; }

		iframe {
			width: 100% !important; } } }
