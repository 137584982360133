
html,
body {
  width: 100%;
  height: 100%; }

html {
  font-family: $got;

  --title-spacing: 100px;

  @include temp {
    --title-spacing: 60px; }

  @include xs {
    --title-spacing: 0; } }

body {
  width: 100%;
  background: #fff;
  color: $black;
  font-family: $got;
  font-size: 16px;
  line-height: 1.36;
  &.is-no-scroll {
    overflow: hidden; } }

a {
  color: #18db73;
  transition: all linear .2s;

  &:hover {
    text-decoration: none; } }

.clearfix:after {
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
  content: ".";
  font-size: 0; }

.clearfix {
  zoom: 1; }

.ir {
  font: 0/0 a;
  text-shadow: none;
  color: transparent; }

.out {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  min-width: 300px; }

.container {
  --container: 20px;
  position: relative;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  padding: 130px var(--container);

  @include sm {
    padding: 65px var(--container); } }

.sec-checkout {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.bg-black {
  background: $black;
  color: $white;

  & + .bg-black {
    .container {
      padding-top: 0;

      @include sm {
        padding-top: 0; } } } }

.d-block {
  display: block; }

.d-flex {
  display: flex; }

.d-none {
  display: none; }

.my-0 {
  margin-top: 0;
  margin-bottom: 0; }

.mt-0 {
  margin-top: 0; }

.mb-0 {
  margin-bottom: 0; }

.py-0 {
  padding-top: 0;
  padding-bottom: 0; }

.pt-0 {
  padding-top: 0; }

.pb-0 {
  padding-bottom: 0; }

.shedule-v2, .shedule-v3 {
  padding-top: 120px !important;
  @include md {
    padding-top: 40px !important; } }

#schedule {
  padding: 80px 0; }

#popup-ticket {

  .pop-ticket {

    &__btn {
      color: $white;
      background: transparent;
      border: 2px solid $themeColor;

      &:hover {
        background: $themeColor;
        color: $black; }

      &:after,
      &:before {
        display: none; } }

    &__input {
      outline: none;
      border: none; }

    &__input_btn {
      color: $white;
      background: $black;
      border: 2px solid $themeColor;
      cursor: pointer;
      transition: all ease 0.1s;

      &:hover {
        background: $themeColor;
        color: $black;

        svg {
          fill: $black; } } } } }

b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $got; }

.price-increase {
  &__button {
    .btn {
      @extend .btn--green; } } }

@media (min-width: 768px) {
  .visible-sm {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm {
    display: none !important; } }

#bubble {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
  pointer-events: none;
  @include sm {
    display: none; }
  &.no-animation {
    .bubble {
      animation: none !important; } } }

@keyframes animateBubble {
  0% {
    top: 100vh; }

  100% {
    top: calc((100vh + 110px) * -1); } }

@keyframes sideWays {
  0% {
    margin-left: 0px; }
  50% {
    margin-left: 25px; }
  100% {
    margin-left: 0px; } }

/* ANIMATIONS */

.bubble {
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), inset 0px 8px 25px 5px var(--accentColor);
  height: 100px;
  position: absolute;
  width: 100px;
  top: 100vh;

  &--1 {
    animation: animateBubble 8s linear, sideWays 4s ease-in-out infinite;
    left: 5%;
    margin-top: 60%;
    transform: scale(0.14) rotate(10deg); }

  &--2 {
    animation: animateBubble 13s linear, sideWays 3s ease-in-out infinite alternate;
    left: 70%;
    margin-top: 10%;
    transform: scale(0.3) rotate(10deg); }

  &--3 {
    animation: animateBubble 11s linear, sideWays 4s ease-in-out infinite alternate;
    left: 90%;
    margin-top: 50%;
    transform: scale(0.2) rotate(0deg); }

  &--4 {
    animation: animateBubble 17s linear, sideWays 3s ease-in-out infinite;
    left: 20%;
    margin-top: 35%;
    transform: scale(0.5) rotate(210deg); }

  &--5 {
    animation: animateBubble 10s linear, sideWays 5s ease-in-out infinite alternate;
    animation-delay: 17.5s;
    left: 4.8%;
    margin-top: 20%;
    transform: scale(0.1) rotate(50deg); }

  &--6 {
    animation: animateBubble 11.5s linear, sideWays 6s ease-in-out infinite alternate;
    animation-delay: 17.5s;
    left: 3%;
    margin-top: calc(20% + 25px);
    transform: scale(0.12) rotate(10deg); }

  &--7 {
    animation: animateBubble 11s linear, sideWays 8s ease-in-out infinite alternate;
    animation-delay: 17.5s;
    left: 3%;
    margin-top: calc(20% + 45px);
    transform: scale(0.14) rotate(0deg); }

  &--8 {
    animation: animateBubble 12s linear, sideWays 5s ease-in-out infinite alternate;
    animation-delay: 17.5s;
    left: 3.6%;
    margin-top: calc(20% + 65px);
    transform: scale(0.1) rotate(260deg); }

  &--9 {
    animation: animateBubble 10s linear, sideWays 2s ease-in-out infinite alternate;
    animation-delay: 19s;
    left: 90%;
    margin-top: 9%;
    transform: scale(0.2) rotate(0deg); }

  &--10 {
    animation: animateBubble 10s linear, sideWays 4s ease-in-out infinite alternate;
    animation-delay: 14s;
    left: 50%;
    margin-top: 25%;
    transform: scale(0.4) rotate(140deg); }

  &--11 {
    animation: animateBubble 13s linear, sideWays 4s ease-in-out infinite alternate;
    animation-delay: 14s;
    left: 70%;
    margin-top: 30%;
    transform: scale(0.5) rotate(210deg); } }
