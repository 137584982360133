.perks {
	padding: 100px 0;
	@include md {
		padding: 30px 0; }
	&:not(:last-child) {
		border-bottom: 1px solid #c1c1c1; }
	&__inner {
		display: flex;
		justify-content: space-between;
		max-width: 1274px;
		margin: 0 auto;
		@include md {
			flex-direction: column; } }
	&__img {
		width: 47%;
		margin-right: 60px;
		flex-shrink: 0;
		padding-top: 5px;
		@include md {
			margin-right: 0;
			width: 100%;
			margin-bottom: 20px;
			padding-top: 0; }
		img {
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 400px;
			display: block;
			margin: 0 auto;
			@include md {
				max-width: 60%;
				max-height: 300px; }
			@include sm {
				max-width: 80%;
				max-height: 200px; }
			@include xs {
				max-width: 100%;
				max-height: 200px; } } }

	&__video-wrap {
		width: 47%;
		margin-right: 60px;
		flex-shrink: 0;
		margin: 5px 60px 0 0;

		iframe {
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			position: absolute; }

		@include md {
			margin-right: 0;
			width: 100%;
			margin-bottom: 20px;
			padding-top: 0; } }

	&__video {
		position: relative;
		padding-top: 56%;
		width: 100%; }


	&__content,
	&__title {
		font-size: 26px;
		flex: 1 1 auto;
		color: $black;
		margin-bottom: 35px;
		@include bold;

		@include md {
			margin-bottom: 15px;
			font-size: 22px; } }
	&__subtitle {
		font-size: 20px;
		color: #888;
		margin-bottom: 35px;
		@include bold;

		@include md {
			margin-bottom: 15px;
			font-size: 18px; } }
	&__description {
		color: #888;
		font-size: 15px;
		line-height: 2;
		margin-bottom: 43px;
		letter-spacing: 1.1px;
		@include md {
			margin-bottom: 20px; }
		@include sm {
			color: #aaaaaa; }

		ul {
			list-style: disc; }

		a {
			color: $themeColorD; }

		p {
			&:not(:last-child) {
				margin-bottom: 23px;
				@include md {
					margin-bottom: 10px; } } } }
	&__link {
		text-decoration: none;
		color: $themeColorD;
		font-size: 14px;
		text-transform: uppercase;
		&:hover {
			text-decoration: underline; } } }
