// width
@mixin r($width) {
  @media only screen and (max-width: $width+ "px") {
    @content; } }

@mixin rmin($width) {
  @media only screen and (min-width: $width+ "px") {
    @content; } }

// height
@mixin rh($height) {
  @media only screen and (max-height: $height + "px") {
    @content; } }

@mixin rhmin($height) {
  @media only screen and (min-height: $height + "px") {
    @content; } }

@mixin xxl {
  @media only screen and (max-width: 1480px) {
    @content; } }

@mixin xl {
  @media only screen and (max-width: 1280px) {
    @content; } }

@mixin laptop {
  @media only screen and (max-width: 1280px) {
    @content; } }

@mixin md {
  @media only screen and (max-width: 1024px) {
    @content; } }

@mixin tablet {
  @media only screen and (max-width: 1024px) {
    @content; } }

@mixin temp {
  @media only screen and (max-width: 890px) {
    @content; } }

@mixin sm {
  @media only screen and (max-width: 767px) {
    @content; } }

@mixin xs {
  @media only screen and (max-width: 599px) {
    @content; } }

@mixin xxs {
  @media only screen and (max-width: 480px) {
    @content; } }

@mixin xxxs {
  @media only screen and (max-width: 374px) {
    @content; } }

@mixin font($alias, $name, $weight) {
  @font-face {
    font-family: $alias;
    src: url("../fonts/" + $name + ".woff2") format("woff2"), url("../fonts/" + $name + ".woff") format("woff");
    font-style: normal;
    font-weight: $weight;
    font-display: swap; } }
