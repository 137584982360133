.s-aftermovie {
	.container {
		padding: 50px 20px; } }

.aftermovie {
	position: relative;
	padding-top: 56.5%;

	iframe {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%; } }
