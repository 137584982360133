
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  transition: all 0.2s linear;
  position: relative;
  &-site {
    transform: scale(1.2); } }
