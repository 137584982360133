@mixin placeholder {
   ::-webkit-input-placeholder {
    @content; }

   :-moz-placeholder {
    @content; }

   ::-moz-placeholder {
    @content; }

   :-ms-input-placeholder {
    @content; } }

@mixin selection {
   ::-moz-selection {
    @content; }

   ::selection {
    @content; } }

@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip; }

@mixin bold {
  font-weight: 700; }

@mixin medium {
  font-weight: 500; }

@mixin regular {
  font-weight: 400; }


@mixin ellipsis($var) {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $var;
  -webkit-box-orient: vertical; }
