.zoom-bars {}

.bars-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 70px 0 50px;
	color: #fff;

	@media all and (max-width: 1300px) {
		margin: 70px -20px 50px; }

	@include md {
		margin: 40px -20px 30px; }

	@include sm {
		margin: 40px 0 30px; }

	&__item {
		position: relative;
		width: 400px;
		padding: 42px 30px 44px;
		font-size: 20px;
		overflow: hidden;

		@media all and (max-width: 1300px) {
			width: 33.3%; }

		@include md {
			font-size: 2vw; }

		@include sm {
			width: 50%;
			font-size: 3vw; }

		@include xs {
			width: 100%; }

		@include xs {
			font-size: 5vw; }

		@include xxs {
			font-size: 7vw; }

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.4); } }

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover; }

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		z-index: 1;
		height: 100%; }

	&__bar {
		font-size: 56px;
		line-height: 1;
		font-weight: 800;
		text-transform: uppercase;
		text-align: center;
		margin: 0 0 20px;

		@media all and (max-width: 1300px) {
			font-size: 3.9vw; }

		@include sm {
			font-size: 6vw; }

		@include xs {
			font-size: 9vw; }

		@include xxs {
			font-size: 12vw; } }

	&__name {
		font-size: 20px;
		line-height: 1.2;
		margin: 0 0 54px;

		@include md {
			font-size: 2vw;
			margin: 0 0 4vw; }

		@include sm {
			font-size: 3vw;
			margin: 0 0 6vw; }

		@include xs {
			font-size: 5vw;
			margin: 0 0 9vw; }

		@include xxs {
			font-size: 7vw;
			margin: 0 0 12vw; } }

	&__rooms {
		display: flex;
		margin: auto 0 0; }

	&__room {
		position: relative;
		border: 13px solid #fff;
		border-radius: 50%;
		overflow: hidden;
		flex: 0 0 auto;
		background: #fff;
		cursor: pointer;

		@media all and (max-width: 1300px) {
			border-width: 1vw; }

		@include sm {
			border-width: 1.5vw; }

		@include xs {
			border-width: 2.3vw; }

		@include xxs {
			border-width: 3.5vw; }

		&+.bars-list__room {
			margin: 0 0 0 -62px;

			@include sm {
				margin: 0 0 0 -5vw; }

			@include xs {
				margin: 0 0 0 -10vw; }

			@include xxs {
				margin: 0 0 0 -15vw; } }

		&:after {
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			content: 'JOIN';
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			opacity: 0;
			transition: all ease 0.1s;
			color: $themeColor; } }

	button.bars-list__room:hover {
		border-color: $themeColor;

		&:after {
			opacity: 1; } }


	&__photo {
		display: block;
		width: 175px;
		height: 175px;

		@media all and (max-width: 1300px) {
			width: 13.5vw;
			height: 13.5vw; }

		@include sm {
			width: 20vw;
			height: 20vw; }

		@include xs {
			width: 30vw;
			height: 30vw; }

		@include xxs {
			width: 45vw;
			height: 45vw; } } }
