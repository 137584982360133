.techs {
  display: flex;
  flex-wrap: wrap;
  margin: 32px auto 0;
  padding: 0;
  align-items: stretch;
  justify-content: center;

  @include xs {
    margin: 32px -10px 10px; }

  &__item {
    display: flex;
    justify-content: center;
    min-width: 85px;
    margin: 0 0 20px;
    flex-direction: column;
    padding: 0 5px;
    min-width: 92px;
    justify-content: flex-start;

    @include md {
      min-width: auto;
      width: 80px; }
    @include sm {
      width: 20%; }
    @include xs {
      width: 25%; } }

  &__link {
    text-decoration: none;
    color: #fff;
    transition: color .1s;

    &:hover {
      text-decoration: underline;
      color: $themeColor; } }

  &__img-box {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 8px;
    @include xs {
      margin: 0 0 0; }

    img {
      transform: scale(0.80);
      max-height: 58px;

      @include md {
        transform: scale(0.75); } } }

  &__title {
    font-size: 13px;
    text-align: center;
    margin: 0;

    @include sm {
      font-size: 12px; } } }
