.notice-panel {
  z-index: 9;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 50px 20px 20px;
  color: $black;
  opacity: 1;
  transition: all ease 0.1s;

  &.hide {
    opacity: 0;
    visibility: hidden; }

  @include md {
    bottom: 0;
    padding: 10px 40px 10px 20px; }
  @include xs {
    padding: 10px 35px; }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    z-index: 2;
    cursor: pointer;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 1px;
      background: $black;

      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg); }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg); } } }

  &:after {
    content: '';
    background: $themeColor;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    z-index: 1; }

  .container {
    padding: 0;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px 30px;

    @include xs {
      flex-direction: column; } }

  &__desc {
    font-size: 16px;
    font-weight: 600;

    color: $black;

    @include md {
      font-size: 14px; }

    @include xs {
      text-align: center; } }

  &__btn {
    position: relative;
    flex: 0 0 auto;

    @include xs {
      margin: 0; } } }
