.s-quake {
  background: $black; }

.quake {
  position: relative;

  &__body {
    --block-padding: 55px;
    position: relative;
    min-height: 514px;
    flex-direction: column;
    padding: var(--block-padding);

    @include md {
      --block-padding: 36px; }

    @include sm {
      --block-padding: 20px; }

    @include sm {
      min-height: 300px; }

    @include xs {
      min-height: 185px; }

    &:after {
      content: '';
      position: absolute;
      left: -40px;
      top: -40px;
      width: 132px;
      height: 132px;
      background: $themeColor;
      z-index: 0;

      @include xl {
        left: -20px;
        top: -20px; }
      @include sm {
        display: none; } }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: url(../img/quake.gif) center center no-repeat;
      background-size: cover; } }

  &__descr {
    display: flex;
    flex-direction: column; }

  &__title.section__title {
    text-align: center;
    margin: 0 auto;
    padding-left: 60px;
    color: $white;
    align-items: center;
    position: relative;
    z-index: 1;

    @include xs {
      text-align: left;
      padding: 40px 0 0 40px; } }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;

    @include sm {
      font-size: 18px; } }

  &__text {
    font-size: 16px;
    line-height: 1.42;
    margin: 8px 0 0;

    @include sm {
      font-size: 14px;
      text-align: left; } }

  &__cfa {
    --spacing: 30px;
    background: $white;
    color: $black;
    position: absolute;
    right: calc(var(--spacing) * -1);
    bottom: calc(var(--spacing) * -1);
    padding: 30px;
    width: 530px;
    display: flex;
    z-index: 1;

    @include xl {
      --spacing: 20px; }

    @include sm {
      position: relative;
      right: auto;
      bottom: auto;
      width: 100%;
      padding: 20px; }

    @include xs {
      padding-bottom: 98px; } }

  &__img {
    display: block;
    width: 170px;
    margin: 0 22px 0 0;
    padding: 0;
    flex: 0 0 170px;

    @include sm {
      flex-basis: 140px; }

    @include xs {
      flex-basis: 80px; }

    img {
      width: 100%;
      height: auto;
      display: block; } }

  &__btn {
    width: 100%;
    margin: auto 0 0;

    @include xs {
      position: absolute;
      left: 20px;
      bottom: 20px;
      right: 20px;
      width: auto; } } }
