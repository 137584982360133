
 :root {
  --accentColor: #24fc89;
  --price-increase-bg: #171717;
  --price-increase-color: #fff;
  --price-increase-border: rgba(255,255,255,0.25);

  --checkout-section-bg: #fff;
  --checkout-section-color: #000;
  --checkout-card-border: 1px solid #DDD;
  --checkout-card-bg: #fff;
  --checkout-card-text-color: #000;
  --checkout-quantity-hover-color: #000;
  --checkout-quantity-border: 1px solid #DDD;
  --checkout-quantity-button-bg: #24fc89;
  --checkout-quantity-button-bg-hover: #000;
  --checkout-quantity-button-icon-color: #000;
  --checkout-quantity-button-icon-color-hover: #fff;
  --checkout-form-ff: GothamPro,Arial,Helvetica,sans-serif;
  --checkout-form-border: 1px solid #DDD;
  --checkout-form-color: #000;
  --checkout-price-ff: GothamPro,Arial,Helvetica,sans-serif;
  --checkout-card-title-weight: 500;
  --checkout-button-weight: 700;
  --checkout-button-border: 1px solid #24fc89;
  --checkout-button-border-hover: 1px solid #000;

  --cehckout-badge-bg: #ddd;
  --cehckout-badge-color: #000;

  --price-section-bg: #171717;
  --price-color: #fff;
  --price-item-bg: rgb(41, 41, 41);
  --price-item-border: none;
  --price-item-radius: 8px;
  --price-item-title-color: #fff;
  --price-item-title-weight: 700;
  --price-item-date-color: $black;
  --price-item-date-size: 14px;

  --price-slider-btn-size: 54px;
  --price-slider-btn-border-color: #24fc89;
  --price-slider-btn-radius: 0;
  --price-slider-btn-color: #000;
  --price-slider-btn-bg: #24fc89;
  --price-slider-btn-bg-hover: #18db73;

  --price-btn-color: #fff;
  --price-btn-border: 1px solid #24fc89;
  --price-btn-border-color-hover: #18db73;

  --workshop-item-ff: 'GothamPro';
  --workshop-item-color: #fff;
  --workshop-item-border: #3F3F3F;
  --workshop-item-bg: #292929;
  --workshop-item-type-bg: #fff; }

/*----- FONTS -----*/

$got: 'GothamPro', Arial, Helvetica, sans-serif;
$rb: 'RumbleBrave', Arial, Helvetica, sans-serif;

// end FONTS

/*----- COLORS -----*/

$text: #000000;
$black: #171717;
$white: #fff;
$themeColor: #24fc89;
$themeColorD: #18db73;

// end COLORS

/*----- SCHEDULE -----*/
$posTop: 84px;
$scheduleColorGray: #c5c5c5;
$scheduleColorGrayL: #eaeaec;
$scheduleOutline: #8D8D8D;
// end SCHEDULE

/*----- SWITCH -----*/
$switchBG: #b1ec00;
$switchColor: $black;
$switchHandler: $black;

// end SWITCH
