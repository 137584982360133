#programmes {
  padding: 65px 0;
  .container {
    padding-top: 0;
    padding-bottom: 0; } }
.content-tab {
  position: relative;
  // top: 200px
  text-align: center;
  display: flex;
  max-width: 968px;
  margin: 40px auto -2px;
  border: 2px solid $themeColor;

  &.max-width {
    max-width: none; }

  &__tab {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 58px;
    line-height: 1.28;
    cursor: pointer;
    background: transparent;
    border: none;
    color: $black;
    background: $white;
    flex: 1 1 auto;
    padding: 5px 10px;
    outline: none;

    &.is-active {
      background: $themeColor; } }

  &__octo {
    content: '';
    position: absolute;
    right: -226px;
    top: -202px;
    width: 668px;
    height: 450px;
    background: url(../img/decor_octo2.png) center center no-repeat;
    background-size: 100%;
    z-index: 2;
    pointer-events: none; }

  @include sm {
    margin: 28px 0 -2px;

    &__tab {
      font-size: 16px;
      min-height: 48px;

      span {
        display: none; } }

    &__octo {
      right: -126px;
      top: -112px;
      width: 370px;
      height: 250px; } }

  @include xs {
    &__tab {
      font-size: 12px; } } }

.schedule-list {
  position: relative;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 1.56;
  max-width: 968px;
  margin: 0 auto 70px;
  border: 2px solid $themeColor;
  padding: 84px 0;
  background: $white;
  z-index: 1;

  &.js-tab {
    display: none; }

  &.alligned-margin {
    margin: 0 0 -130px; }

  &.is-active {
    display: block; }

  &.max-width {
    max-width: none; }

  &__container {
    max-width: 900px;
    margin: 0;
    padding: 0; }

  &__container {
    max-width: 900px;
    margin: 0;
    padding: 0; }

  &__track-info {
    text-align: center;
    padding: 0 20px;
    margin: 0 0 30px;

    @include sm {
      margin: 20px 0; } }

  &__item {
    position: relative;
    width: 100%;
    clear: both;
    margin: 0;
    padding: 18px 0;

    &:before {
      content: '';
      position: absolute;
      left: 35%;
      top: 0;
      bottom: 0;
      width: 2px;
      background: $themeColor;
      transform: translateY(30px); }

    &:after {
      width: 8px;
      height: 8px;
      border: 2px solid $themeColor;
      content: '';
      position: absolute;
      left: calc(35% + 1px);
      top: 28px;
      transform: translateX(-50%);
      border-radius: 50px;
      background: $white; }

    &:last-child {

      &:before {
        display: none; } }

    &.clickable {
      cursor: pointer; }

    &.open {
      .schedule-list__time {
        span {
          background: $black;
          color: $white; } }

      .schedule-list__title {
        span {
          background: $black;
          color: $white; } } }

    &.active {
      &:after {
        background: $themeColor; } }

    &.green {
      color: $themeColorD;

      &:after {
        background: $themeColor;
        border-color: $themeColor; } }

    &.disconnected {
      &:before {
        display: none; }

      &.green {
        &:after {
          width: 4px;
          height: 4px; } } }

    &.single {
      margin: 70px 0 0;

      &:first-child {
        margin: 0; }

      .schedule-list__sep {
        position: absolute;
        left: 35%;
        top: 28px;
        bottom: 28px;
        width: 2px;
        background: $themeColor; }

      &:before {
        width: 8px;
        height: 8px;
        border: 2px solid $themeColor;
        content: '';
        position: absolute;
        left: calc(35% + 1px);
        top: 28px;
        transform: translateX(-50%);
        border-radius: 50px;
        background: $white;
        z-index: 1; }

      &:after {
        width: 8px;
        height: 8px;
        border: 2px solid $themeColor;
        content: '';
        position: absolute;
        left: calc(35% + 1px);
        top: auto;
        bottom: 28px;
        transform: translateX(-50%);
        border-radius: 50px;
        background: $white;
        z-index: 1; }

      .schedule-list__title {
        font-size: 35px;
        margin: -12px 0 -6px; }

      .schedule-list__speaker {
        font-size: 18px;
        color: $themeColorD;
        font-weight: 700; } } }

  &__line {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 20px 0 0;

    &:first-child {
      margin: 0; } }

  &__left {
    max-width: 35%;
    width: 100%;
    padding: 0 60px 0;
    text-align: right; }

  &__right {
    max-width: 65%;
    flex-grow: 1;
    padding: 0 100px 0 60px;

    p {
      &:first-child {
        margin-top: 0; } } }

  &__l-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 0 0; }

  &__r-text {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 0 0; }

  &__time {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.2;
    max-width: 35%;
    width: 100%;
    padding: 0 60px 0;
    margin: 0 0 0 0;
    text-align: right;

    span {
      padding: 0 2px;
      transition: all ease .1s; } }

  &__desc {
    max-width: 65%;
    flex-grow: 1;
    padding: 0 100px 0 60px; }

  &__title {
    font-size: 22px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0;
    padding: 1px 0 0;

    span {
      display: inline-block;
      padding: 1px 2px 2px;
      transition: all ease .1s; } }

  &__speaker {
    font-size: 18px;
    line-height: 1.2;
    width: 100%;
    margin: 6px 0 0; }

  &__btn {
    height: 32px;
    margin: 8px 0 0; }

  &__p {
    font-size: 16px;
    line-height: 1.56;
    margin: 14px 0 0;
    padding: 0;
    color: #5f5f5f; }
  &__info {
    display: none;
    // padding-top: 6px
    .schedule-list__p {
      margin-top: 0;

      + .schedule-list__l-title {
        margin-top: 14px; } }

    .schedule-list__l-title {
      margin-top: 6px;
      line-height: 1.32; }

    p {
      margin: 6px 0 0; } }

  &__pic {
    float: left;
    margin: 0 24px 0 0; }

  @include sm {
    padding: 20px 0;

    &__item {
      padding: 15px 12px 15px 12px;

      &:after {
        left: -1px; }

      &:before {
        left: -2px; }
      &.single {
        margin: 50px 0 0;

        &:before {
          left: -1px; }
        &:after {
          left: -1px; }

        .schedule-list__sep {
          left: -2px; }

        .schedule-list__title {
          font-size: 24px;
          margin: -4px 0 -2px; }

        .schedule-list__speaker {
          font-size: 16px; } } }

    &__line {
      flex-direction: column;
      flex-wrap: wrap; }

    &__left,
    &__right {
      padding: 0;
      max-width: none; }

    &__left {
      text-align: left; }

    &__time {
      max-width: 60px;
      margin: 0 10px 0 0;
      text-align: left;
      padding: 0;
      font-size: 22px;

      span {
        padding: 2px;
        margin: 0 0 0 -2px; } }

    &__desc {
      max-width: none;
      padding: 0; }

    &__title {
      font-size: 18px;
      padding: 0;
      span {
        padding: 2px;
        margin: 0 0 0 -2px; } }

    &__speaker {
      font-size: 16px;
      margin: 0;
      max-width: none;

      .schedule-list__p {
        margin: 0 0 12px; } } }

  ul {
    font-size: 16px;
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 1.22;
    margin: 4px 0;

    li {
      position: relative;
      padding: 0 0 0 32px;
      margin: 14px 0 0 0;

      &:first-child {
        margin-top: 0; }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        width: 16px;
        height: 2px;
        background: $themeColorD; } } } }

.dashed-list {
  font-size: 16px;
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1.22;
  margin: 4px 0;

  &__item {
    position: relative;
    padding: 0 0 0 32px;
    margin: 14px 0 0 0;

    &:first-child {
      margin-top: 0; }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
      width: 16px;
      height: 2px;
      background: $themeColorD; } }

  &.sub {
    font-size: 14px;

    .dashed-list__item {
      padding: 0 0 0 20px;
      margin: 14px 0 0 0;

      &:before {
        top: 6px;
        width: 5px;
        height: 5px;
        border-radius: 50%; } } } }
