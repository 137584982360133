.random-party {
	padding: 42px 0 42px;
	background: url(../img/screens-done-op.svg) center center no-repeat;
	background-color: #19181b;
	color: $white;
	@include xl {
		padding: 40px 0 40px; }
	@include md {
		padding: 50px 0 50px; }
	@include xs;
	.section__title {
		letter-spacing: 1.3px;
		margin-bottom: 55px;
		padding: 0;
		text-align: center;
		color: $themeColor;
		@include xl {
			margin-bottom: 37px; } }
	.container {
		@include xs {
			padding: 0 16px; } }
	&__inner {
		padding: 82px 20px 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include xl {
			padding: 40px 20px; }
		@include md {
			padding: 0 20px;
			border: none; } }

	&__text {
		line-height: 1.6;
		letter-spacing: 0.003em;
		max-width: 540px;
		text-align: center;
		font-size: 18px;
		margin-bottom: 42px;
		@include xl {
			font-size: 16px;
			margin-bottom: 38px; } }

	&__btn {
		max-width: 368px;
		width: 100%;
		margin: 0 auto;
		height: 60px;
		border: 2px solid $themeColor;
		background: transparent;
		color: $white;
		cursor: pointer;
		font-size: 14px;
		font-weight: 700;
		transition: all ease 0.1s;

		&:hover {
			background: $themeColor;
			color: $black; } } }
