
.section {
  overflow: clip;
  &__head {
    display: flex;

    &.centered {
      justify-content: center;

      .section__head-l {
        width: min-content; } }

    .section__head-l {
      max-width: 50%;
      width: 100%;
      padding: 0 0 0 100px;

      .section__title {
        padding: 80px 0 0 0; }

      &.w-100 {
        max-width: none; } }

    .section__head-r {
      margin: 96px 0 0;
      max-width: 50%;
      width: 100%;
      padding: 0 0 8px 20px;
      max-width: 480px;

      &.no-width {
        max-width: 50%; }

      .slider-wrap {
        margin-right: -126px;
        margin-bottom: 70px;
        @include temp {
          margin-bottom: 0;
          margin-top: 30px; } }
      @include xxl {
        .slider-wrap {
          margin-right: 0; } }

      ul {
        font-size: 16px;
        padding: 0;
        margin: 0;
        list-style: none;
        line-height: 1.22;
        margin: 4px 0;

        li {
          position: relative;
          padding: 0 0 0 32px;
          margin: 14px 0 0 0;

          &:first-child {
            margin-top: 0; }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 9px;
            width: 16px;
            height: 2px;
            background: $themeColorD; } } } } }

  &__title-wrap {
    padding-left: 30px; }

  &__title {
    position: relative;
    font-size: 52px;
    line-height: 1.22;
    font-weight: 700;
    margin: 0;
    padding: 54px 0 0 0;

    .initial {
      position: relative;
      top: -8px;
      width: 0;
      font-family: $rb;
      font-size: 150px;
      line-height: 24px;
      font-weight: 400;
      margin: 0 0 0 0;
      color: $themeColor;

      span {
        display: inline-block;
        position: absolute;
        right: 100%; }

      &.w {
        left: 10px; } }

    &.with-dots:before {
      content: '';
      position: absolute;
      left: -170px;
      top: -90px;
      width: 505px;
      height: 359px;
      z-index: -1;
      background: url(../img/title-drops.png) no-repeat;
      pointer-events: none; }

    &.small {
      font-size: 35px;

      .initial {
        top: -2px;
        font-size: 70px; } } }

  &__desc {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;

    p {
      margin: 16px 0 0;

      &:first-child {
        margin: 0; } }

    ul {
      font-size: 16px;
      padding: 0;
      margin: 0;
      list-style: none;
      line-height: 1.22;
      margin: 4px 0;

      li {
        position: relative;
        padding: 0 0 0 32px;
        margin: 14px 0 0 0;

        &:first-child {
          margin-top: 0; }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 9px;
          width: 16px;
          height: 2px;
          background: $themeColorD; } } } }

  &.black {
    background: $black;

    .section {
      &__head {
        color: $white; } } }

  &.about {
    .container {
      position: relative;
      padding: 100px 25px;
      z-index: 1;
      justify-content: space-between; }

    @include temp {
      .container {
        flex-direction: column;
        padding: 60px 25px; } } }

  &.speakers {
    .container {
      padding-bottom: 0;

      @include temp {
        padding: 60px 20px 0; }

      @include xs; } }

  @include md {
    &__title {
      font-size: 46px;

      .initial {
        font-size: 140px; } } }

  @include temp {
    &__head {
      flex-direction: column;

      .section__head-l {
        max-width: none;
        padding: 0 0 0 60px; }

      .section__head-r {
        margin: 10px 0 0;
        max-width: none;
        padding-left: 60px;

        &.no-width {
          max-width: none;

          .loc-slider-nav {
            &:after {
              display: none; } } } } }

    &__title {
      font-size: 38px;

      .initial {
        font-size: 128px; } } }

  @include sm {
    &__desc {
      font-size: 14px; } }

  @include xs {
    &__title {
      font-size: 32px;
      padding: 12px 0 0 44px;

      .initial {
        font-size: 102px;
        position: relative;
        right: 0;
        top: -5px; } }

    &__head {
      .section__head-l,
      .section__head-r {
        padding: 0;

        .section__title {
          padding: 40px 0 0 44px; } } } }

  &.s-multipass {
    position: relative;

    &:before {
      content: '';
      width: 50vw;
      top: 228px;
      left: 54.5%;
      position: absolute;
      height: 226px;
      background: url(../img/faces.svg) left top;
      clip-path: polygon(30px 0, 100% 0, 100% 100%, 30px 100%, 30px calc(100% - 75px), 0 calc(100% - 75px), 0 75px, 30px 75px);

      @include sm {
        left: 10px;
        width: 100vw;
        top: 30px; } }
    .section__title {
      color: $themeColor; }
    .section__subtitle {
      font-weight: 500;
      font-size: 20px;
      margin: 32px 0 28px;

      @include sm {
        font-size: 18px;
        margin: 22px 0 20px; } } } }
