.sponsors {
  .container {
    padding-bottom: 0; }

  .section {
    &__desc {
      a {
        font-weight: 700;
        color: #0F8546; } } }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0; } }
.sponsors-block {
  margin: 130px 0 50px;
  @include md {
    margin: 75px 0 50px; }
  @include sm {
    margin: 48px 0 50px; }

  & + & {
    margin-top: 50px;
    @include sm {
      margin-top: 30px; } }

  &__title {
    position: relative;
    font-size: 30px;
    font-weight: 700;
    width: 100%;
    margin: 0 0 0 5px;

    @include sm {
      font-size: 20px;
      margin-bottom: 32px; }

    &:after {
      content: '';
      position: absolute;
      left: -8px;
      bottom: -5px;
      height: 20px;
      width: 92px;
      background: #24fc89;
      z-index: -1;

      @include sm {
        height: 14px;
        width: 68px;
        bottom: 0; } } }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -20px;
    @include sm {
      margin: 0 -10px; } }

  &__item {
    text-align: center;
    width: 100%;

    @include tablet {
      display: flex;
      justify-content: center; }

    @include sm {
      max-width: 230px; }

    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s;
      padding: 20px;
      @include sm {
        padding: 10px; }

      &:hover {
        opacity: 0.7; }

      picture {
        display: block; }

      img {
        display: block;
        max-width: 100%;
        transition: all ease 0.2s;
        transform: translateY(0);
        opacity: 1;
        height: auto; } }

    &--w-info {
      width: 100% !important;
      max-width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 1; } }
  &__content {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    line-height: 1.45;
    @include sm {
      font-size: 10px; }
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none; } }
    p:not(:last-child) {
      margin-bottom: 10px; } }
  &__subtitle {
    font-weight: 600;
    margin-bottom: 5px; }
  &.Platinum {
    .sponsors-block__item {
      width: 50%;
      max-width: 50%;
      @include sm {
        width: 100%;
        max-width: 100%; } } }

  &.Gold {
    .sponsors-block__item {
      width: 33.33%;
      max-width: 33.3%;
      @include xs {
        width: 50%;
        max-width: 50%; } } }

  &.Silver {
    .sponsors-block__item {
      width: 25%;
      max-width: 25%;

      @include xs {
        width: 33.3%;
        max-width: 33.3%; } } }

  &.MediaPartner,
  &.Partner,
  &.TechPartner,
  &.EntertainmentPartner {

    .sponsors-block__item {
      width: 20%;
      max-width: 20%;

      @include xs {
        width: 25%;
        max-width: 25%; } } }

  &_auto {
    .sponsors-block__list {
      justify-content: space-between; }

    .sponsors-block__item {
      width: auto;

      @include sm {
        width: 25%; }
      @include xs {
        width: 33.3%; }

      img {
        max-width: 100%; }

      &:last-child {
        margin: 0 auto 0 0; } } } }
