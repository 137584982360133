.s-workshops-list {
  background: #171717;
  padding: clamp(80px, 9vw, 130px) 0 !important;
  .section__title {
    color: #24FC89;
    text-transform: none !important;
    padding-top: 0;
    padding-left: 0;
    &::first-letter {
      font-family: RumbleBrave,Arial,Helvetica,sans-serif;
      font-size: 240%;
      font-weight: 400;
      vertical-align: .055em;
      letter-spacing: -.1em; } }
  .section__info {
    color: #24FC89; }
  .container {
    padding-top: 0;
    padding-bottom: 0; }

  &__row {
    .btn {
      @extend .btn--green; }
    &-title {
      color: #919191; } } }

.workshops-list {
  &__buttons {
    .btn {
      --_offset: 50px;
      text-transform: none !important;
      padding-left: 18px !important;
      padding-right: var(--_offset) !important;
      &:hover, &:focus-visible {
        padding-left: var(--_offset) !important;
        padding-right: 18px !important; } }

    .btn:not(.btn--secondary) {
      @extend .btn--green; } } }
