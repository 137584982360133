/* Fonts
 *----------------------------------------------------------------------------- */

@font-face {
  font-family: "RumbleBrave";
  src: url(../fonts/RumbleBrave.woff2) format("woff2"), url(../fonts/RumbleBrave.woff) format("woff");
  font-style: normal;
  font-weight: 400; }

@include font(GothamPro, GothamProLight, 300);
@include font(GothamPro, GothamPro, 400);
@include font(GothamPro, GothamProMedium, 500);
@include font(GothamPro, GothamProBold, 700);
@include font(GothamPro, GothamProBlack, 900);
