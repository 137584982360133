.s-free-ticket {
  padding: clamp(50px, 5vw, 100px) 0;
  background: #171717;
  color: $white;
  *:focus-visible {
    outline: 2px solid rgba(#fff, 0.9) !important; }
  .container {
    max-width: 1320px;
    padding-top: 0 !important;
    padding-bottom: 0 !important; }

  .section {
    &__title {
      margin: 0 auto 26px;
      max-width: 1062px;
      position: relative;
      z-index: 2;
      color: $themeColor;
      @include md {
        br {
          display: none; } } }
    &__head-l {
      max-width: 100%; } } }

.free-ticket {

  &__content {
    display: flex;
    max-width: 1062px;
    margin: 0 auto;
    gap: 20px 76px;
    justify-content: space-between;

    @include md {
      gap: 20px 40px;
      flex-direction: column-reverse; } }

  &__text {

    flex: 0 1 520px;

    @include md {
      flex: 1 1 auto; }

    .btn {
      margin-top: 30px;
      @include md {
        margin: 30px 0 0; }
      @include sm {
        font-size: 12px; } } }

  &__descr {

    font-size: 16px;
    line-height: 1.5;

    em {
      color: $themeColor;
      font-style: normal; }

    p {
      &:not(:first-child) {
        margin: 24px 0 0; } } }

  &__img {
    flex: 0 0 413px;

    @include md {
      flex: 0 0 auto;
      margin: 0 auto; }

    @include xs {
      max-width: 232px; }

    img {
      display: block;
      width: 100%;
      height: auto; } } }

.badge-img {
  position: relative;
  z-index: 1;
  width: 413px;
  @include sm {
    width: 234px; }
  &:after {
    content: '';
    position: absolute;
    right: 140px;
    bottom: calc(100% - 27px);
    height: 150px;
    width: 44px;
    background: linear-gradient(0deg, #F2F2F2 0%, rgba(255,255,255,0) 100%);
    z-index: -1;
    transform: skew(-32deg);
    border-radius: 0 0 2px 2px;
    @include md {
      height: 100px;
      right: calc(50% - 55px); }

    @include sm {
      width: 24px;
      height: 64px;
      right: 89px;
      bottom: calc(100% - 20px);
      border-radius: 0 0 1px 1px; } }

  &__card {
    position: relative;
    padding: 10px 30px 30px;
    border-radius: 20px;
    border: 1px solid #24FC89;
    background: #171717;
    @include sm {
      border-radius: 10px;
      padding: 10px 10px 20px; } }

  &__body {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 8%; }

  &__img {
    margin: 0 20px 0 0;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    border: 1px solid rgba(#24FC89, 0.7);
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;

    @include sm {
      width: 54px;
      height: 54px;
      margin: 0 12px 0 0; }

    .icon-face {
      width: 46px;
      height: 58px;
      fill: $white;

      @include sm {
        width: 26px;
        height: 34px; } } }

  &__desc {

    div:first-child {
      font-size: 32px;
      line-height: 1;
      font-weight: 700;
      font-stretch: 120%;
      margin: 0 0 2px;
      &::first-letter {
        font-family: 'RumbleBrave';
        color: $themeColor;
        font-size: 150%;
        font-weight: 400; }

      @include sm {
        font-size: 18px; } }

    div:nth-child(2) {
      font-size: 12px;
      margin: 8px 0 0;
      line-height: 0.9;

      @include sm {
        font-size: 8px;
        margin: 5px 0 0; } } }

  &__barcode {
    position: relative;
    width: 106px;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='106.5px' height='20px' viewBox='0 0 106.5 20' style='enable-background:new 0 0 106.5 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ffffff;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M0,0h20v6.7H0L0,0z'/%3E%3Cpath class='st0' d='M0,13.3h6.7V20H0L0,13.3z'/%3E%3Cpath class='st0' d='M6.7,6.7h6.7v6.7l-6.7,0L6.7,6.7z'/%3E%3Cpath class='st0' d='M13.3,13.3l6.7,0V20h-6.7L13.3,13.3z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M27,20V0l3.5,0v20H27z'/%3E%3Cpath class='st0' d='M33.9,20V0l1.2,0v20H33.9z'/%3E%3Cpath class='st0' d='M36.2,20V0l2.3,0v20H36.2z'/%3E%3Cpath class='st0' d='M40.8,20V0L42,0v20H40.8z'/%3E%3Cpath class='st0' d='M43.1,20V0l1.2,0v20H43.1z'/%3E%3Cpath class='st0' d='M45.4,20V0l1.2,0v20H45.4z'/%3E%3Cpath class='st0' d='M47.7,20V0l3.5,0v20H47.7z'/%3E%3Cpath class='st0' d='M54.7,20V0l1.2,0v20H54.7z'/%3E%3Cpath class='st0' d='M57,20V0l2.3,0v20H57z'/%3E%3Cpath class='st0' d='M61.6,20V0l1.2,0v20H61.6z'/%3E%3Cpath class='st0' d='M63.9,20V0L65,0v20H63.9z'/%3E%3Cpath class='st0' d='M71.9,20V0l1.2,0v20H71.9z'/%3E%3Cpath class='st0' d='M74.2,20V0l3.5,0v20H74.2z'/%3E%3Cpath class='st0' d='M81.2,20V0l1.2,0v20H81.2z'/%3E%3Cpath class='st0' d='M83.5,20V0l2.3,0v20H83.5z'/%3E%3Cpath class='st0' d='M88.1,20V0l1.2,0v20H88.1z'/%3E%3Cpath class='st0' d='M90.4,20V0l1.2,0v20H90.4z'/%3E%3Cpath class='st0' d='M92.7,20V0l1.2,0v20H92.7z'/%3E%3Cpath class='st0' d='M95,20V0l3.5,0v20H95z'/%3E%3Cpath class='st0' d='M101.9,20V0l1.2,0v20H101.9z'/%3E%3Cpath class='st0' d='M104.2,20V0l2.3,0v20H104.2z'/%3E%3C/g%3E%3C/svg%3E") center center no-repeat;

    @include sm {
      width: 62px;
      height: 12px; } }

  &__stripe {
    position: absolute;
    width: 60px;
    height: 18px;
    border: 1px solid #24FC89;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    border-radius: 12px;
    background: #171717;

    @include sm {
      width: 40px;
      height: 12px;
      top: 12px; }

    &:before {
      content: '';
      position: absolute;
      left: -40px;
      bottom: calc(100% - 2px);
      height: 150px;
      width: 44px;
      background: linear-gradient(0deg, #F2F2F2 0%, #F2F2F2 50%, rgba(255,255,255,0) 100%);
      transform: skew(32deg);
      border-radius: 0 0 2px 2px;
      @include md {
        height: 100px;
        left: -24px; }
      @include sm {
        width: 24px;
        height: 64px;
        left: -13px;
        border-radius: 0 0 1px 1px; } } }

  &__head {
    font-size: 18px;
    line-height: 1;
    font-weight: 400;

    padding: 17px 0 25px;
    margin-bottom: 24px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(#24FC89, 0.7);

    @include sm {
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 15px;
      margin-bottom: 20px; }

    .icon {
      width: 10px;
      height: 10px;
      fill: $white;

      @include sm {
        width: 8px;
        height: 8px; } } } }
